<template>
  <div v-if="preview">{{ dateFormat(value, format) || '-' }}</div>
  <PreviewEditableDate
    v-else
    v-model:open="open"
    v-bind="$props"
    @change="(...args: [string, string?]) => $emit('change', ...args)"
    @click="$emit('click')"
    @update:open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import type { FieldCallback } from '#field/types'

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: '',
  },
  preview: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  format: {
    type: String,
    default: 'yyyy MMM dd',
  },
  cellClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'click'): void
  (e: 'update:open', value: boolean): void
}>()

const open = ref(false)

defineExpose({ focus: () => (open.value = true) })

onMounted(() => {
  if (!props.preview) {
    emit('set-callback', {
      open: () => {
        open.value = true
      },
    })
  }
})
</script>
