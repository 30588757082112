<template>
  <UInput
    v-model="dateInput"
    placeholder="Date"
    class="w-full px-3 py-3"
    @blur="onChangeDate($event.target.value)"
    @keydown.enter.prevent.stop="
      ;($event.target as HTMLTextAreaElement)?.blur()
    "
  />

  <DatePicker
    :model-value="internalValue"
    @update:model-value="onChange"
  />
</template>

<script lang="ts" setup>
const props = defineProps({
  popoverRef: {
    type: Object as PropType<Ref<{ ref: () => { open: boolean } }>>,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  format: {
    type: String,
    default: 'yyyy MMM dd',
  },
  cellClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'click'): void
  (e: 'update:open', value: boolean): void
  (e: 'close'): void
  (e: 'update:internal-value', value: string): void
}>()

const internalValue = ref(dateFormat(props.value))
const dateInput = ref(dateFormat(props.value))
const open = ref(props.popoverRef.ref().open)

const onChange = (value: string) => {
  internalValue.value = dateFormat(value)
  dateInput.value = dateFormat(value)
  if (internalValue.value != props.value) {
    emit('change', internalValue.value, props.fieldId)
    emit('update:internal-value', internalValue.value)
  }
}

const onChangeDate = (value: string) => {
  const partialValue = dateFormat(value as string)
  dateInput.value = partialValue ? partialValue : ''
  internalValue.value = dateInput.value
  emit('update:internal-value', internalValue.value)
}

defineExpose({
  internalValue: internalValue,
})

watch(
  () => props.value,
  (val) => {
    internalValue.value = dateFormat(val)
    dateInput.value = dateFormat(val)
  }
)

defineShortcuts({
  escape: {
    whenever: [open],
    handler: () => {
      emit('close')
    },
  },
})
</script>
