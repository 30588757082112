<template>
  <div
    :class="[
      'relative h-full w-full group/cell',
      { 'cursor-not-allowed': disabled },
    ]"
  >
    <DeferredPopover
      ref="popoverRef"
      :ui="{
        trigger: `h-full flex ${disabled ? '!cursor-not-allowed' : ''}`,
        wrapper: 'h-full',
      }"
      :disabled="disabled"
      v-bind="$attrs"
      @update:open="$emit('update:open', $event)"
    >
      <template #content>
        <PreviewPopoverContentDate
          :popover-ref="popoverRef"
          v-bind="$props"
          @close="popoverRef.close()"
          @change="$emit('change', $event, fieldId)"
          @update:internal-value="internalValue = $event"
        />
      </template>
      <div
        :class="[
          'w-full h-full flex items-center',
          cellClass,
          { active: popoverRef?.isOpen },
        ]"
      >
        <UButton
          variant="ghost"
          :ui="{
            font: 'font-normal',
          }"
          :class="[
            {
              'text-gray-400': !internalValue,
            },
            'bg-transparent text-black hover:bg-transparent text-xs px-2',
          ]"
          :disabled="disabled"
          @click="emit('click')"
        >
          {{ dateFormat(internalValue, format) }}
          <span
            v-if="!internalValue"
            class="flex items-center gap-1 group-hover/cell:visible invisible"
          >
            -
          </span>
        </UButton>
      </div>
    </DeferredPopover>
    <UButton
      v-if="internalValue && !disabled"
      size="2xs"
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark"
      class="absolute right-1 top-1/2 -translate-y-1/2 invisible group-hover/cell:visible"
      @click="$emit('change', '', fieldId)"
    />
  </div>
</template>

<script lang="ts" setup>
defineProps({
  value: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  preview: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  format: {
    type: String,
    default: 'yyyy MMM dd',
  },
  cellClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'click'): void
  (e: 'update:open', value: boolean): void
}>()

const popoverRef = ref()
const internalValue = ref()
</script>
